<!--  -->
<template>
  <div style="height: 100%;">
    <a-row style="height: 100%;">
      <a-col :span="4">
        <!-- 左边竖线样式写法 -->
        <div class="titleBox">
        <div class="titleLine" style=""></div>
          <div>网格列表</div>
        </div>
        <div style="margin-top: 3%; padding-right: 2%">
          <el-input
            placeholder="输入关键字进行过滤"
            prefix-icon="el-icon-search"
            v-model="filterText"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="data"
            @node-click="treeClick"
            :filter-node-method="filterNode"
            ref="tree"
          >
          </el-tree>
        </div>
      </a-col>
      <a-col
        :span="20"
        style="height: 100%; border-left: 1px solid #d8d8d8; padding: 1%"
      >
        <ax-table
          ref="logTable"
          :columns="columns"
          :searchActions="searchActions"
          :show-search="true"
          :showToolBar="true"
          :searchForm="searchForm"
          :rowSelection="rowSelection"
          @addGridialog="addGridialog"
          @export="expor"
          @bulkDeletion="deleteSecurityPersonnel"
          :dataSourceParams="dataSourceParams"
          :dataSourceApi="api.list"
          @pop-confirm="popConfirm"
          @action-column-click="actionColumnClick"
        >
          <div slot="gridRole" slot-scope="{ record }">
            {{ record.gridRole === "0" ? "网格长" : "网格员" }}
          </div>
        </ax-table>
      </a-col>
    </a-row>
    <!-- 新增/编辑弹窗 -->
    <el-dialog
      :title="titles"
      :visible.sync="visible"
      width="30%"
      :z-index="99"
      :close-on-click-modal="false"
    >
      <ax-form ref="form" :formBuilder="formBuilder" @change="changes" />
      <addGrid
        v-for="(row, index) in gridList"
        :key="row"
        @delForm="delForm(index)"
        :title="row"
        ref="formBox"
        @addForms="addForms"
      >
      </addGrid>
      <div
        class="gridConfig"
        @click="gridConfig"
        v-if="titles === '新增网格员'"
      >
        + 新增网格配置
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formSave"
          :loading="saveType"
          style="background-color: #2A5CFF"
          >保 存</el-button
        >
        <el-button @click="formCancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "./api";
import addGrid from "./components/addGrid";
const options = [
  {
    label: "网格长",
    value: "0",
  },
  {
    label: "网格员",
    value: "1",
  },
];
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "keyword",
    options: { placeholder: "请输入姓名/手机号" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "角色",
    type: "select",
    model: "gridRole",
    options: { options, placeholder: "请选择角色" },
    formItem: {},
    col: { span: 6 },
  },
];
const formList = [
  {
    label: "网格员",
    type: "treeSelect",
    model: "gridWorkerId",
    options: {},
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "网格名称" }],
  },
];
export default {
  components: { addGrid },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "gridWorkerName",
            ellipsis: true,
            key: 2,
            width: 100,
          },
          {
            title: "部门",
            dataIndex: "depName",
            ellipsis: false,
            key: 3,
            width: 80,
          },
          {
            title: "责任网格",
            dataIndex: "gridName",
            ellipsis: true,
            key: 4,
            width: 130,
          },
          {
            title: "角色",
            dataIndex: "gridRole",
            ellipsis: false,
            key: 5,
          },
          {
            title: "手机号",
            dataIndex: "mobilePhone",
            ellipsis: true,
            key: 6,
          },
          {
            title: "邮箱",
            dataIndex: "mail",
            ellipsis: true,
            key: 7,
          },
          // {
          //   title: "座机",
          //   dataIndex: "phone",
          //   ellipsis: true,
          //   key: 8,
          // },
        ],
        true,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#2A5CFF",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                  // disabled: !(record.status === "1"),
                };
              },
            },
          ],
        }
      ),
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      tableData: [],
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      rowSelection: { onChange: this.onSelectChange },
      searchActions: [
        { name: "addGridialog", text: "新增网格员"},
        { name: "export", text: "导出", type: "#67c23a" },
        { name: "bulkDeletion", text: "批量删除", type: "#FA5959" },
        { name: "search", text: "查询"},
        { name: "reset", text: "重置"},
      ],
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
      dataSourceParams: { gridId: "" },
      // 左树
      filterText: "",
      data: [],
      // 弹窗
      visible: false,
      gridList: ["网格配置1"],
      formArr: [],
      treeID: "",
      titles: "",
      formId: "",
      saveType: false,
      // 多选
      // selectedRowKeys: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  computed: {},

  methods: {
    expor() {
      api.export({
        fileName: "网格员管理.xls",
        params: "",
      });
    },
    // 操作
    actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        case "edit":
          this.gridDetail(record.id);
          this.formId = record.id;
          break;
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.dels(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.logTable.getDataSource();
    },
    // 树过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 获取网格点
    async getgridTree() {
      try {
        const res = await api.gridTree();
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        this.data = treeData;
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 树点击
    async treeClick(e) {
      if (e.value) {
        this.dataSourceParams.gridId = e.value;
        this.$refs.logTable.getDataSource();
        this.treeID = e.value;
      }
    },
    // 新增
    addGridialog() {
      this.titles = "新增网格员";
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.formBox.forEach((item) => {
          item.seleteMsg(this.data);
          item.resetForm();
        });
        this.deptTree();
        this.$refs.form.resetFields();
      });
    },
    // 编辑
    async gridDetail(id) {
      this.titles = "编辑网格员";
      this.visible = true;
      this.formArr = [];
      // 详情
      const res = await api.gridDetail(id);
      
      this.$nextTick(() => {
        this.$refs.formBox.forEach((item) => {
          item.getGridInfo(id);
          // 责任网格树数据
          item.seleteMsg(this.data);
        });
        this.deptTree();
        this.$refs.form.setFieldsValue({ gridWorkerId: res.data.gridWorkerId });
      });
    },
    // 新增网格配置
    gridConfig() {
      const match = this.gridList[this.gridList.length - 1].match(/\d+/);
      const number = Number(match[0]);
      const newGridTitle = "网格配置" + (number + 1);
      this.gridList.push(newGridTitle);
      this.$nextTick(() => {
        this.$refs.formBox.forEach((item) => {
          item.seleteMsg(this.data);
        });
      });
    },
    // 部门人员树
    async deptTree() {
      const res = await api.deptTree();
      this.GetreeArr(res.data);
    },
    GetreeArr(data) {
      data.forEach((item) => {
        if (item.phone === null) {
          item.disabled = true;
        }
        if (item.children && item.children.length > 0) {
          this.GetreeArr(item.children);
        }
      });
      this.$refs.form.setFormItemProp("gridWorkerId", {
        options: {
          treeData: data,
          allowClear: true,
        },
      });
    },
    delForm(index) {
      this.gridList.splice(index, 1); // 删除指定索引的证件行数据
    },
    addForms(e) {
      this.formArr.push(e);
    },
    // 弹窗提交
    formSave() {
      this.$refs.formBox.forEach((item) => {
        item.save();
      });
      // 循环校验
      if (this.formArr.length !== this.$refs.formBox.length) {
        this.formArr = [];
        return;
      }
      // if (!this.formArr.length) return;
      // 循环校验是否有重复网格
      const gridIdCount = {};
      let hasDuplicate = false;
      this.formArr.forEach((item) => {
        if (gridIdCount[item.gridId]) {
          gridIdCount[item.gridId]++;
          hasDuplicate = true;
          return;
        } else {
          gridIdCount[item.gridId] = 1;
        }
      });

      if (hasDuplicate) {
        this.$message.error("存在重复责任网格");
        this.formArr = [];
        return;
      }
      this.saveType = true;
      // 校验
      this.$refs.form.form.validateFields(async (err, value) => {
        if (!value.gridWorkerId) return;
        if (err) return;
        if (this.titles === "新增网格员") {
          try {
            const res = await api.add({
              gridWorkerId: value.gridWorkerId,
              gridConfigList: this.formArr,
            });

            if (res.status === 200) {
              this.$message.success("添加成功");
              this.$refs.form.resetFields();
              this.$refs.formBox.forEach((item) => {
                item.resetForm();
              });
              this.$refs.logTable.getDataSource();
              this.saveType = false;
            }
          } catch (error) {
            console.log(error);
            this.saveType = false;
          }
        } else if (this.titles === "编辑网格员") {
          try {
            const res = await api.edit({
              id: this.formId,
              gridWorkerId: value.gridWorkerId,
              gridconfig: this.formArr[0],
            });

            if (res.status === 200) {
              this.$message.success("编辑成功");
              this.$refs.form.resetFields();
              this.$refs.formBox.forEach((item) => {
                item.resetForm();
              });
              this.$refs.logTable.getDataSource();
              this.saveType = false;
            }
          } catch (error) {
            console.log(error);
            this.saveType = false;
          }
        }
      });
      this.visible = false;
      this.gridList = ["网格配置1"];
      // 刷新列表数据
    },
    formCancel() {
      this.$refs.form.resetFields();
      this.$refs.formBox.forEach((item) => {
        item.resetForm();
      });
      this.visible = false;
      // 刷新列表数据
      this.$refs.logTable.getDataSource();
      this.gridList = ["网格配置1"];
    },
    changes(e) {
      console.log(e, "id");
    },
    // 多选删除
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async () => {
            // 使用箭头函数确保this指向组件实例
            const res = await api.deleteBatch(this.selectedRowKeys);
            if (res.status === 200) {
              this.$message.success("删除成功");
              // 刷新列表数据
              this.$refs.logTable.getDataSource();
            } else {
              this.$message.error("删除失败");
            }
          },
        });
      }
    },
  },

  created() {},

  mounted() {
    this.getgridTree();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='less' scoped>
.titleBox{
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
  .titleLine{
    height: 0.09rem;
    width: 0.015rem;
    background-color:#2a5cff; 
    margin-right: 5px;
  }
}


.filter-tree {
  margin-top: 2%;
}

.gridConfig {
  width: 100%;
  height: 0.2rem;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed #2A5CFF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2A5CFF;
  cursor: default;
}

/deep/.is-current {
  color: #2A5CFF !important;
}
/deep/.el-tree-node__content {
  height: 0.16rem;
}
/deep/.el-tree-node__content {
  &:hover {
    background-color: #e8eef4;
  }
}
/deep/.el-tree-node.is-current > .el-tree-node__content {
  background-color: #e8eef4 !important;
}
// /deep/.el-tree-node__label {
//       font-size: 0.07rem !important;
//     }

/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>